<script>
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'
import { getContent, deleteContent } from '@/api/admin/contents.js'

import Dropdown from '@/components/ui/Dropdown.vue'
import AddBtn from '@/components/ui/AddBtn.vue' // 등록
import { ref } from 'vue'
const isLoading = ref(false)

export default {
  name: 'NoticeList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    Dropdown
  },
  data() {
    return {
      currentPath: this.$route.fullPath, // 현재 접속 경로

      // syncfusion grid cell 안에서 버튼 사용
      commands: [
        { buttonOption: { content: '수정', cssClass: 'e-info' } },
        { buttonOption: { content: '보기', cssClass: 'btn-warning' } },
        { buttonOption: { content: '삭제', cssClass: 'e-danger' } }
      ],
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'],
      format: { type: 'dateTime', format: 'y/M/d' },
      content: [], // 더미데이터,
      persistedData: [],
      searchConditions: [
        { value: 'contentId', text: '번호' },
        { value: 'title', text: '제목' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },
  mounted() {
    this.setSearchOptions()

    const getContentList = async () => {
      isLoading.value = true

      const data = await getContent()
      if (!(data instanceof Array)) {
        return (isLoading.value = false)
      }

      this.content = data.map((item) => {
        return {
          contentId: item.contentId,
          title: item.title,
          content: item.content,
          contentEn: item.contentEn,
          updatedAt: item.updatedAt,
          createdAt: item.createdAt
        }
      })
      this.persistedData = this.content

      isLoading.value = false
    }
    getContentList()
  },
  methods: {
    write() {
      this.$router.push(`${this.currentPath}/w`)
    },
    commandClick(e) {
      if (e.target.classList.contains('e-info')) return this.modifyContent(e)
      if (e.target.classList.contains('btn-warning')) return this.showContent(e)
      if (e.target.classList.contains('e-danger')) return this.delContent(e)
    },
    modifyContent(e) {
      const id = e.rowData.contentId
      this.$router.push(`${this.currentPath}/w/${id}`)
    },
    showContent(e) {
      const id = e.rowData.contentId

      this.$router.push(`/about/${id}`)
    },
    async delContent(e) {
      if (!confirm('정말로 삭제하시겠습니까?')) return

      const id = e.rowData.contentId
      await deleteContent(id)

      // 삭제 성공 callback
      const gridInstance = this.$refs.grid.ej2Instances
      gridInstance.dataSource = this.setDataSource(id)
      alert('삭제되었습니다.')
    },
    setDataSource(id) {
      this.content = this.content.filter((item) => item.contentId !== id)
      return this.content
    },
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      this.data = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  }
}
</script>

<template>
  <AddBtn @click="write()"></AddBtn>

  <Dropdown
    class="select-wrapper"
    :options="searchConditions"
    :disabled="false"
    name="zipcode"
    ref="selectOption"
    @selected="selectDropdownHandler"
  ></Dropdown>

  <div class="">
    <ejs-grid
      :dataSource="content"
      :allowPaging="true"
      :pageSettings="pageSettings"
      :allowSorting="true"
      :toolbar="toolbarOptions"
      :commandClick="commandClick"
      :actionComplete="actionComplete"
      ref="grid"
    >
      <e-columns>
        <e-column field="contentId" headerText="번호" width="50" textAlign="center"></e-column>
        <e-column field="title" headerText="제목" width="150" textAlign="left"></e-column>
        <e-column field="updatedAt" headerText="수정일" width="100" :format="format" type="date" textAlign="center"></e-column>
        <e-column field="createdAt" headerText="등록일" width="100" :format="format" type="date" textAlign="center"></e-column>
        <e-column field="Commands" headerText="관리" width="150" textAlign="left" :commands="commands"> </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<style scoped>
* {
  margin: 0;
}
</style>
